import React from 'react';
import "./hurry.css"; 

const Hurry = (props) => {
  const handelClose=()=>{
    setTimeout(() => {
      props.setIsHurry(false);
      
    }, 250);

  }



  return (
    <div className='h-con'>
        <h2 className='h-head'>**Hurry Up**</h2>
        <h3 className='h-main'>

        Registration will close on 13 March 7:00 pm 
        </h3>
        <button className="glowing-button3" id='h-close-btn' onClick={handelClose} >CLOSE</button>


        </div>
  )
}

export default Hurry