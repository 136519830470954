import React, { useEffect, useState, useMemo, useCallback } from 'react';
import "./contest.css";
import ContCard from './ContCard';
import { useFirebase } from '../../backend/Firebase';


const Contest = (props) => {
  const [contest, setContest] = useState([]);
  const [isopen, setIsopen] = useState(true);
  const firebase = useFirebase();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const contests = await firebase.contest();
        const contestData = contests.docs.map((doc) => doc.data());
        setContest(contestData);
      } catch (error) {
        console.error("Error fetching contests:", error);
      }
    };

    fetchData();
  }, []);

  const handlePaymentClick = useCallback(() => {
    setTimeout(() => {
      firebase.handlePayment();
    }, 350);
  }, [firebase]);

  const goToCartClick = useCallback(() => {
    props.setIsLeft(true);
  }, [props]);

  const contestCards = useMemo(() => (
    contest.map((contestData, index) => (
      <ContCard
        key={index}
        data={contestData}
        isopen={isopen}
        setIsopen={setIsopen}
      />
    ))
  ), [contest, isopen]);

  return (
    <div className='wrp-event' id='contest-wrap' style={{ position: "relative" }}>
      <h2 className='ev-head'>EVENTS</h2>
      <div className='ev-con'>{contestCards}</div>
      <div className='p2p'>
        <button className="glowing-button2" id='pay-btn' onClick={handlePaymentClick}>Payment</button>
        <button className="glowing-button2" id='pay-btn' onClick={goToCartClick}>Go To Cart</button>
      </div>
    </div>
  );
};

export default Contest;
