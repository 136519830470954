import { createContext, useContext, useState, useEffect } from "react";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut, sendEmailVerification, sendPasswordResetEmail  } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, getDoc, setDoc, doc } from "firebase/firestore";
import { getStorage, ref,  getDownloadURL } from "firebase/storage";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";




const FirebaseContext = createContext(null);

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
  };


const firebaseApp = initializeApp(firebaseConfig);
const firebaseAuth = getAuth(firebaseApp);
const googleProvider = new GoogleAuthProvider();
const fireStore = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);


export const useFirebase = () => useContext(FirebaseContext);


export const FirebaseProvider = (props) => {

    const [isAdmin, SetisAdmin] = useState(true)
    const [isVerified, SetIsVerified] = useState(true)
    const [isMobile, setIsMobile] = useState(false)
    const [isWarn, setIsWarn] = useState(false)

    const [user, setUser] = useState(null);
    const [login, setLogin] = useState(false);
    const [selectContest, setSelectContest] = useState([]);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [amt, setAmt] = useState(0);

    const [buyContest, setBuyContest] = useState([]);
    const [contestDB, setContestDB] = useState([]);





    const navigate = useNavigate();






    useEffect(() => {
        // Fetch user from local storage
        const storedUser = localStorage.getItem('userL');

        // Parse the stored user if it exists
        const parsedUser = storedUser ? JSON.parse(storedUser) : null;

        // Set the user in the component state
        setUser(parsedUser);
        // console.log("set user in firebase main user", parsedUser)
        storeUserInFirestore(user);


    }, []);

    useEffect(() => {
        // Fetch user from local storage
        const storedUser = localStorage.getItem('userL');

        // Parse the stored user if it exists
        const parsedUser = storedUser ? JSON.parse(storedUser) : null;

        // Set the user in the component state
        setUser(parsedUser);
        // console.log("set user in firebase main user", parsedUser)
        storeUserInFirestore(user);
    }, [login]);

  


    useEffect(() => {
        onAuthStateChanged(firebaseAuth, user => {
            // console.log(user);
            if (user) {
                setUser(user);
            } else {
                setUser(null)
            }
        });




    }, [])

    useEffect(() => {
        handleAuthStateChange();


        // Clean up the listener when the component unmounts

    }, [firebaseAuth]);




    const handleAuthStateChange = () => {
        onAuthStateChanged(firebaseAuth, (user) => {
            if (user) {
                // Check if user's email is verified
                if (user.emailVerified) {
                    SetIsVerified(true);
                    setIsWarn(false);
                    // Update user's profile or perform other actions
                    console.log("User's email is verified:", user.email);
                    // For example, you can update user's profile in Firestore
                    // firestore.collection("users").doc(user.uid).update({ emailVerified: true });
                } else {
                    SetIsVerified(false);
                    console.log("User's email is not verified:", user.email);
                }
            } else {
                console.log("No user signed in.");
            }
        });
    };

    // Call handleAuthStateChange to start listening for authentication state changes

    const SignupUserWithEmailandPassword = async (email, password) => {
        try {
            const userCredential = await createUserWithEmailAndPassword(firebaseAuth, email, password);
            const user = userCredential.user;
            console.log("User created:", user);
            localStorage.setItem('userL', JSON.stringify(user));
            setLogin(true);
            navigate("../home");
            storeUserInFirestore(user);
            toast.success("User created successfully!");
    
            // Now that the user is created, wait for the user to be fully initialized
            firebaseAuth.onAuthStateChanged((currentUser) => {
                if (currentUser) {
                    // Once the user is fully initialized, resend the verification email
                    resendVerificationEmail(currentUser);
                }
            });
        } catch (error) {
            console.error("Error creating user:", error);
            // Handle error
        }
    };

    const handleResetPassword = (email) => {
        sendPasswordResetEmail(firebaseAuth, email) // Use sendPasswordResetEmail function
            .then(() => {
                toast.success('Password reset email sent. Check your inbox!');
            })
            .catch((error) => {
                toast.error(`Error sending password reset email: ${error.message}`);
            });
    };
    
    

    const resendVerificationEmail = (user) => {
        // Check if user object is valid
        if (!user || typeof user !== 'object') {
            console.error('Invalid user object:', user);
            return;
        }

        // Check if user has the getIdToken method
        if (typeof user.getIdToken !== 'function') {
            console.error('User object does not have the getIdToken method:', user);
            toast.error("Reload and Try again after sometime");
            return;
        }

        // Send email verification
        sendEmailVerification(user)
            .then(() => {
                toast.success("Verification email sent. Please check your inbox and verify your email address.");
            })
            .catch((error) => {
                console.error("Error sending verification email:", error);
                toast.error("Error sending verification email: " + error.message);
            });
    };


    const SigninUserWithEmailandPassword = (email, password) => {
        signInWithEmailAndPassword(firebaseAuth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                console.log("User signed in:", user);
                // toast("User signed in successfully!");
                toast.success("User signed in successfully!", user);
                localStorage.setItem('userL', JSON.stringify(user));
                setLogin(true);
                navigate("../home")
                storeUserInFirestore(user);
            })
            .catch((error) => {
                console.log("Error signing in:", error);
                toast.error("Error signing in:", error);
            });
    };



    const singinWithGoogle = () => {
        signInWithPopup(firebaseAuth, googleProvider)
            .then((userCredential) => {
                const user = userCredential.user;
                console.log("User signed in with Google:", user);
                navigate("../home")
                storeUserInFirestore(user);
                toast.success("User signed in with Google successfully!", user);

                // Store user information in local storage
                localStorage.setItem('userL', JSON.stringify(user));
                setLogin(true);

                // You may want to navigate to another page or perform additional actions here
            })
            .catch((error) => {
                console.error("Error signing in with Google:", error);
                toast.error("Error signing in with Google: " + error.message);
            });
    };


    console.log(user)






    const getImageURL = async (path) => {
        try {
            const fileRef = ref(storage, path); // Create a reference to the specific file
            const downloadURL = await getDownloadURL(fileRef); // Get the download URL
            return downloadURL;
        } catch (error) {
            console.error('Error getting image URL:', error);
            throw error; // You can handle or propagate the error as needed
        }
    };
    const logOut = () => {
        signOut(firebaseAuth);
        localStorage.removeItem('userL');
        toast.info("Logout");
        setLogin(false);
        console.log("lougout sucessfuly")


    }
    const isMobileDevice = () => {
        const viewportWidth = window.innerWidth;
        // You can adjust the threshold value as needed
        const mobileThreshold = 768; // Typically, a width below 768px is considered a mobile device

        if (viewportWidth < mobileThreshold) {
            setIsMobile(true);
        } else {
            setIsMobile(false);

        }
        return isMobile;
    }





    const isLogedIn = user ? true : false;




    ////fetct doc


    //     const [contests, setContests] = useState([]);

    //   useEffect(() => {
    //     const fetchContests = async () => {
    //       try {
    //         const contestsCollection = await firestore.collection('contests').get();
    //         const contestsData = contestsCollection.docs.map((doc) => ({
    //           id: doc.id,
    //           ...doc.data(),
    //         }));
    //         console.log("cd",contestsData)
    //         setContests(contestsData);
    //       } catch (error) {
    //         console.error('Error fetching contests:', error);
    //       }
    //     };

    //     fetchContests();
    //   }, []);




    const contest = () => {
        return getDocs(collection(fireStore, "contest"));

    }

    //store all buy contest
    const storeBuyContestArrayInFirestore = async (newContests, response) => {
        try {

            // console.log("newContests = ", newContests);
            // console.log("response = ", response);
            // console.log(response.razorpay_payment_id);

            if (!newContests || !Array.isArray(newContests) || newContests.length === 0) {
                console.error('Error: newContests is not a valid array.');
                return;
            }

            // Loop through each item in the newContests array
            newContests.forEach((item, index) => {
                console.log(`Processing item ${index}:`, item);

                // Add PaymentID property inside each object's main object
                const mainObjectKey = Object.keys(item)[0];
                console.log(`Payment ID for item ${index}:`, response.razorpay_payment_id);
                item[mainObjectKey].PaymentID = response.razorpay_payment_id;
                console.log(`Updated item ${index}:`, item);
            });



            const contestsDocumentRef = doc(getFirestore(), 'buyContest', user.email);

            // Get the current contests array from Firestore
            const contestsDoc = await getDoc(contestsDocumentRef);
            const currentContests = contestsDoc.exists() ? contestsDoc.data()?.contests || [] : [];

            // Add the new contests to the array
            const updatedContests = [...currentContests, ...newContests];

            // Update the contests array in Firestore
            await setDoc(contestsDocumentRef, { contests: updatedContests }, { merge: true });

            setPaymentSuccess(true);
            console.log('BuyContests added to Firestore and successfully payment.');
            toast.success("Payment Successfully Made");
      

        } catch (error) {
            console.error('Error adding BuyContests to Firestore:', error);
            toast.error("Error in Payment");
        }
    };



    const storeUserInFirestore = async (userObject) => {
        try {
            console.log('enter in Firestore function.');
            // Assuming 'users' is your Firestore collection for users
            const userDocumentRef = doc(getFirestore(), 'users', userObject.uid);

            // Check if the user is already registered
            const userDoc = await getDoc(userDocumentRef);

            if (userDoc.exists()) {
                console.log('User is already registered. Skipping storage in Firestore.');
                return;
            }

            const plainUserObject = JSON.parse(JSON.stringify(userObject));

            // If the user is not registered, store the user object in Firestore
            await setDoc(userDocumentRef, plainUserObject);
            // toast.success("User successfully stored");

            console.log('User stored in Firestore successfully.');
        } catch (error) {
            console.error('Error storing user in Firestore:', error);
        }
    };



    const fetchAllBuyContestDocuments = async () => {
        try {
            const buyContestCollectionRef = collection(getFirestore(), 'buyContest');

            // Get all documents from the collection
            const querySnapshot = await getDocs(buyContestCollectionRef);

            // Extract data from each document
            const buyContestDocuments = querySnapshot.docs.map(doc => ({
                id: doc.id,
                data: doc.data() 
            }));

            console.log('All BuyContest documents:', buyContestDocuments);
            setContestDB(buyContestDocuments)

            return buyContestDocuments;
        } catch (error) {
            console.error('Error fetching BuyContest documents from Firestore:', error);
            return [];
        }
    };



    function retrieveAllContests() {


        let allContests = [];

        collection('buyContest').get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    const contestArray = doc.data().contest;
                    allContests = allContests.concat(contestArray);
                });

                console.log(allContests); // Log combined contest arrays
            })
            .catch((error) => {
                console.error("Error getting documents: ", error);
            });
    }

    // Usage


    const handlePayment = async () => {
        if (buyContest.length == 0) {
            toast.info("First Add In Cart");
            return;

        }
        if (buyContest.length != 0 && amt == 0) {
            // toast.info("First Add In Cart");
            storeBuyContestArrayInFirestore(buyContest, {
                razorpay_payment_id: "na"
            });
            return;

        }
       
        const options = {
            key: process.env.REACT_APP_RAZORPAY_KEY,
            keySecret: process.env.REACT_APP_RAZORPAY_KEY_SECRET,
            // key: "rzp_test_Sy31FCpZQSjjKn",
            // keySecret: "0NS4XURgPcuNbFIgLMrcpexv", 
            amount: amt * 100, 
            currency: 'INR',
            name: 'AIEC',
            description: 'Payment for product',
            image: 'https://firebasestorage.googleapis.com/v0/b/aiec-c3a56.appspot.com/o/img%2Fts%20new.jpg?alt=media&token=8c572aeb-9f78-404d-bb03-3dce25ec1430', 
            // Logo URL
            handler: function (response) {
                
                if (response) {
                    storeBuyContestArrayInFirestore(buyContest, response);
                  
                }
              

            },
            prefill: {
                name: user.displayName,
                email: user.email,
                // contact: "8799223344"
            },
            notes: {
                address: 'Razorpay Corporate Office'
            },
            theme: {
                color: '#3399cc'
            }
        };

        // const rzp = new Razorpay(options);
        var pay = new window.Razorpay(options);
        pay.open();

    };


    const fetchDocumentDetails = async (docName) => {
        console.log("called",docName);
        try {
            // Get the document reference
            const docRef = doc(fireStore, 'buyContest', docName);
            
            // Get the document snapshot
            const docSnapshot = await getDoc(docRef);
            
            // Check if the document exists
            if (!docSnapshot.exists()) {
                throw new Error('Document does not exist!');
            }
            
            // Get the data of the document
            const docData = docSnapshot.data();
            
            // Return the document data
            return docData;
        } catch (error) {
            console.error('Error fetching document:', error.message);
            return null;
        }
    };
    
    
    
    
    
    const fetchDataforDetails = async (id) => {
        console.log("called here",id);
        try {
            const docRef = doc(fireStore, 'contest', id);
            
          const docSnapshot = await getDoc(docRef);

          // Check if the document exists
          if (!docSnapshot.exists()) {
              throw new Error('Document does not exist!');
          }

          // Get the data of the document
          const docData = docSnapshot.data();

          // Return the document data
          return docData;
        } catch (error) {
          console.error('Error fetching document:', error);
        }
      };
  
  










    return (
        <FirebaseContext.Provider value={{
            SignupUserWithEmailandPassword,
            firebaseApp,
            SigninUserWithEmailandPassword,
            getImageURL, 
            singinWithGoogle,
            isLogedIn,
         
            contest,
            logOut,
            user,
       
            isMobileDevice,
            login,
            setLogin,
            selectContest,
            setSelectContest,
            buyContest,
            setBuyContest,
            storeBuyContestArrayInFirestore,
            storeUserInFirestore,
            fetchAllBuyContestDocuments,
            contestDB,
            retrieveAllContests,
            handlePayment,
            amt,
            setAmt,
            resendVerificationEmail,
            isVerified,
            isWarn,
            setIsWarn,
            handleAuthStateChange,
            fetchDocumentDetails,
            fetchDataforDetails,
            handleResetPassword,




        }}>
            {props.children}
            <ToastContainer
                position="top-center"
            />
        </FirebaseContext.Provider>
    );
};




